// import Vue from 'vue'
const firebase = require("firebase/app");
import VueFirestore from 'vue-firestore'
require("firebase/auth");
require("firebase/firestore");


const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    databaseURL: process.env.VUE_APP_databaseURL,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
    measurementId: process.env.VUE_APP_measurementId
  };

let firebaseApp = firebase.initializeApp(firebaseConfig);
let firebaseAuth = firebaseApp.auth();
let firebaseDb = firebaseApp.firestore();
// let firebaseStorage = firebaseApp.storage();


let secondaryApp = firebase.initializeApp(firebaseConfig, "secondary");
let Auth2 = secondaryApp.auth();

export {firebase, firebaseAuth, firebaseDb, Auth2, VueFirestore}
