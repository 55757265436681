import Store from '../store'

const ifAuthenticated = (to, from, next) => {
    let user = Store.getters['useraccount/isAuthenticated']
    if(to.path == '/useraccounts-rmt' || to.path == '/reportedrisk' || to.path == '/filemaintenance' && user.department == "RISK MANAGEMENT TEAM"){
      next()
      return
    }

    if (Store.getters['useraccount/isAuthenticated'] !== null) {
      next()
      return
    }
    


    next('/login')
  }
  
//   const ifNotAuthenticated = (to, from, next) => {
//     if (Store.getters['useraccount/isAuthenticated'] === null) {
//       next()
//       return
//     }
//     next('/dashboard')
//   }

const routes = [
    {
        path: '/',
        component: () => import('../layouts/mainLayout.vue'),  
        beforeEnter: ifAuthenticated,  
        children: [
            { path: '', name: 'Dashboard',component: () => import('../views/Dashboard.vue'), beforeEnter: ifAuthenticated },
            { path: '/filemaintenance', name: 'FileMaintenance',component: () => import('../views/FileMaintenance.vue'), beforeEnter: ifAuthenticated },
            { path: '/riskuniverse', name: 'RiskUniverse',component: () => import('../views/RiskUniverse.vue'), beforeEnter: ifAuthenticated },
            { path: '/addnewrisk', name: 'AddNewRisk',component: () => import('../views/AddNewRisk.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewallormp/:id', name: 'ViewAllORMP',component: () => import('../views/ViewAllORMP.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/viewallprmp/:id', name: 'ViewAllPRMP',component: () => import('../views/ViewAllORMP.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/viewallira/:id', name: 'ViewAllIRA',component: () => import('../views/ViewAllIRA.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/viewallcoverage', name: 'ViewAllCoverage',component: () => import('../views/ViewAllCoverage.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewallprmpcoverage', name: 'ViewAllPRMPCoverage',component: () => import('../views/ViewAllPRMPCoverage.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewalliracoverage', name: 'ViewAllIRACoverage',component: () => import('../views/ViewAllIRACoverage.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewassessment', name: 'IRAAssessmentResult',component: () => import('../views/IRAAssessmentResult.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewormp/:id', name: 'ViewORMP',component: () => import('../views/ViewORMP.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/risklevel/:id', name: 'RiskLevel',component: () => import('../views/RiskLevel.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/risklevelprmp/:id', name: 'RiskLevelPRMP',component: () => import('../views/RiskLevel.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/createormprow/:id', name: 'CreateORMPRow',component: () => import('../views/CreateORMPRow.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/createprmprow/:id', name: 'CreatePRMPRow',component: () => import('../views/CreateORMPRow.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/createcoverage', name: 'CreateCoverage',component: () => import('../views/AddNewCoverage.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/createprmpcoverage/:Product', name: 'CreatePRMPCoverage',component: () => import('../views/AddNewCoverage.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/createiracoverage', name: 'CreateIRACoverage',component: () => import('../views/AddNewCoverageIRA.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/useraccounts-rmt', name: 'UserAccounts',component: () => import('../views/RiskManagementTeam/UserAccounts.vue'), beforeEnter: ifAuthenticated },
            { path: '/riskmap', name: 'RiskMap',component: () => import('../views/RiskMap.vue'), beforeEnter: ifAuthenticated },
            { path: '/raeme', name: 'RAEME',component: () => import('../views/RAEME.vue'), beforeEnter: ifAuthenticated,props: route => ({query: route.query.q }) },
            { path: '/reportedrisk', name: 'ReportedRisk',component: () => import('../views/ReportedRisk.vue'), beforeEnter: ifAuthenticated },
            { path: '/activitylogs', name: 'ActivityLogs',component: () => import('../views/ActivityLogs.vue'), beforeEnter: ifAuthenticated },
            { path: '/sendnotifications', name: 'SendNotifications',component: () => import('../views/SendNotifications.vue'), beforeEnter: ifAuthenticated },
            { path: '/addkricoverage', name: 'AddKRICoverage',component: () => import('../views/AddKRICoverage.vue'), beforeEnter: ifAuthenticated },
            { path: '/viewallkri/:id', name: 'ViewAllKRI',component: () => import('../views/ViewAllKRI.vue'), beforeEnter: ifAuthenticated, props: true },
            { path: '/viewallkricoverage', name: 'ViewAllKRICoverage',component: () => import('../views/ViewKRICoverage.vue'), beforeEnter: ifAuthenticated },
            { path: '/ormpupdatelist', name: 'ORMPUpdateList',component: () => import('../views/ORMPUpdateList.vue'), beforeEnter: ifAuthenticated,props: route => ({query: route.query.q }) },
            { path: '/riskreportingform', name: 'RiskReportingForm',component: () => import('../views/RiskReportingForm.vue'), beforeEnter: ifAuthenticated },
            { path: '/riskalerts', name: 'RiskAlerts',component: () => import('../views/RiskAlerts.vue'), beforeEnter: ifAuthenticated },
            { path: '/dashboardlist/:type', name: 'dashboardList',component: () => import('../views/DashboardListPage.vue'), beforeEnter: ifAuthenticated, props: true },
        ],
    },
    {
        path: '/login',
        component: () => import('../layouts/noLayout.vue'),
        children: [
          { path: '', component: () => import('../views/Login.vue') }
        ]
    },
]

export default routes