import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import vuetifydialog from './plugins/vuetify-dialog';
import {firebase, firebaseAuth, firebaseDb, Auth2, VueFirestore} from './plugins/firebase';
import  _ from './plugins/lodash';
import axios from './plugins/axios';
import moment from './plugins/moment';

Vue.config.productionTip = false

Vue.prototype.$firebase = firebase
Vue.prototype.$auth = firebaseAuth
Vue.prototype.$db = firebaseDb  
Vue.prototype.$authRegister = Auth2  
Vue.prototype.$lodash = _
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios

Vue.use(VueFirestore)
Vue.use(firebase)


new Vue({
  router,
  store,
  vuetify,
  firebase,
  axios,
  moment,
  vuetifydialog,
  render: h => h(App)
}).$mount('#app')
