import { firebaseDb, firebase } from '@/plugins/firebase'
import Router from '@/router';

export async function createNotifs ({rootState},payload) {

    const route = Router.currentRoute.name

    const PRMPArrayRoute = ['CreatePRMPCoverage','ViewAllPRMP','RiskLevelPRMP','CreatePRMPRow','ViewAllPRMPCoverage']

    let isPRMP = PRMPArrayRoute.includes(route)
    
    const dashboardUser = rootState.useraccount.dashboardUser

    const dataOwner = rootState.logs.dataOwner
  
    let logs = {
        ...payload,
        ...dashboardUser,
        isPRMP: isPRMP,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        dataOwner: dashboardUser.department == 'RISK MANAGEMENT TEAM' || isPRMP ? dataOwner : null
    }

    try {
        const response = await firebaseDb.collection('ActivityLogs').add(logs)
        if(response) { console.log('%c SUCCESS_SAVING_LOGS','background: #222; color: #bada55') }
    } catch (error) {
        console.log(error,'error')
        console.log('%c ERROR_SAVING_LOGS','background: #D50000; color: #fff')
    }
    
}